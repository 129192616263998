import React from 'react';

export default function Hero({ sectionRefs, index }) {
  return (
    <section
      ref={sectionRefs.current[index]}
      id="intro"
      className="hero-background bg-center bg-position-y-8 md:bg-position-y-0 md:bg-fixed bg-no-repeat bg-cover h-screen relative -mb-16 pb-8 md:mb-0">
      <div className="relative h-screen bg-opacity-20 bg-black">
        <div className="absolute top-2/4 right-0 px-6">
          <h2 className="px-8 mt-7 mb-5 text-tertiary font-extrabold text-xl md:text-4xl xl:text-6xl">
            I want to <span className="bg-primary p-1">build things</span> <br /> for a <span className="border-b-4 border-primary">better tomorrow</span>.
          </h2>
        </div>
      </div>
    </section>
  );
}
