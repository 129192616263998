import React from 'react';

export default function Description() {
  return (
    <div className="text-lg mb-36 md:text-xl">
      <p className="mb-4">
        I love to learn, I love to build, and I love to be creative. I am passionate about web development and creating user-friendly and aesthetically pleasing applications.
      </p>
      <p className="mb-4">
        I am skilled in front- and back-end technologies, frameworks, tools, and methods. I know video editing and production, additionally I can craft print and online media
        content.
      </p>
    </div>
  );
}
